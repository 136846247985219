<template>
  <div class="container" :class="{'mobile': device == 'mobile'}">
    <div class="article animation-slide-bottom">
      <!-- <div class="title" v-if="title">{{ title }}</div> -->
      <!-- <div class="time"  v-if="time">{{ time }}</div> -->
      <div class="content text-justify" v-html="articleTypeContent"></div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "SingleList",
  props: {
    rootColor: String,
    // 列表所属栏目id
    catId: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      articleTypeContent: ''
    };
  },
  watch: {
    catId: {
      handler: function(val) {
        if(!val) return
        this.getData()
      },
      immediate: true,
    },
    articleTypeContent: {
      handler: function(val) {
        if(!val) return 
        this.formateDocument()
      },
      immediate: true,
    }
  },
  created() {
    

  },
  mounted: function() {
    
  },
  methods: {
    formateDocument: function() {
      this.$nextTick(() => {
        document.querySelectorAll('.content p').forEach(p => {
          let img = p.querySelectorAll('img')
          if(p.style.textIndent) p.style.textIndent = '2em'
          if(img.length) {
            p.style.textIndent = 0
            img.forEach(item => {
              item.width = 'auto'
              item.height = 'auto'
              item.style.width = 'auto'
              item.style.height = 'auto'
              item.style.maxWidth = '100%'
              item.onclick = function() {
                window.open(item.src)
              }
            })
          }
          
        });
      })
    },
    getData: function() {
      this.$http.get('/article/article/list', { params: {categoryId: this.catId} }).then(res => {
        this.articleTypeContent = res.rows.length? res.rows[0].content : ''  
      })
    },
    bindPageChange: function(e) {
      this.pageNum = e
      this.getData()

    },

  },
};
</script>



<style lang="scss" scoped>
.container {
  padding: 0 20px;
  // padding-top: 50px;
}
.title {
  text-align: center;
  margin-bottom: 15px;
  font-size: 29px;
  font-weight: 600;
  color: #333333;
}
.time {
  text-align: center;
  margin-bottom: 44px;
  font-size: 16px;
  // font-family: PingFangSC;
  font-weight: 400;
  color: #666666;
}
.content::v-deep {
  // .selectTdClass{background-color:#edf5fa !important}
  table.noBorderTable td,table.noBorderTable th,table.noBorderTable caption{border:1px dashed #ddd !important}
  table{margin-bottom:10px;border-collapse:collapse;display:table;}
  td,th{padding: 5px 10px;border: 1px solid #DDD;}
  caption{border:1px dashed #DDD;border-bottom:0;padding:3px;text-align:center;}
  th{border-top:1px solid #BBB;background-color:#F7F7F7;}
  table tr.firstRow th{border-top-width:2px;}
  .ue-table-interlace-color-single{ background-color: #fcfcfc; }
  .ue-table-interlace-color-double{ background-color: #f7faff; }
  td p{margin:0;padding:0;}
}
.content::v-deep p {
  text-indent: 2em;
  line-height: 2.5em;
}


.container.mobile {
  .title {
    font-size: 18px;
  }
  .time {
    font-size: 12px;
    margin-bottom: 20px;
  }
  .content::v-deep p,
  .content::v-deep span {
    font-size: 0.9em!important;
    line-height: 2em!important;
    overflow: hidden;
  }
}
</style>
