<template>
  <div :class="{'mobile': device == 'mobile'}">
    <div class="navbar h-full-w" style="z-index: 9;">
      <navbar></navbar>
    </div>
    <div v-if="device == 'mobile'" style="height: 64px;"></div>

    <top-img :img="banner"></top-img>

    <!-- //tabs 二级栏目 -->
    <div class="tabs-position">
      <sub-tabs
        :activeKey="cid"
        :tabs="curSubMenu"
        @toggleChangeTab="bindChangeTap"
      ></sub-tabs>
    </div>

    <!-- 内容区域 -->
    <div class="main-content">
      <div v-if="curSubMenuItem">
        <!-- 如果是文章详情 -->
        <template v-if="aid">
          <bread-crumb></bread-crumb>
          <page-article :title="articleObj.title" :time="articleObj.createDate" :content="articleObj.content" :contentPdf="articleObj.contentPdf"></page-article>

        </template>
        <template v-else>
          <sub-title :title="curSubMenuItem.name" :title_en="curSubMenuItem.descp" padding_top="58px" padding_bottom="58px"></sub-title>
          <!-- ‘article’ 文章类型栏目 -->
          <template v-if="curSubMenuItem.type == 'article'">
            <!-- <page-article :content="articleTypeContent"></page-article> -->
            <content-block :cat-id="curSubMenuItem.pkSerial"></content-block>
          </template>

          <!-- 'list' 普通列表 -->
          <template v-if="curSubMenuItem.type == 'list'">
            <hot-news :cat-id="curSubMenuItem.pkSerial" @on-list-click="bindListClick"></hot-news>
            <single-list :cat-id="curSubMenuItem.pkSerial" @on-list-click="bindListClick"></single-list>
          </template>

          <!-- 'list-img' 焦点图列表 -->
          <template v-if="curSubMenuItem.type == 'list-img'">
            <hot-news :cat-id="curSubMenuItem.pkSerial" @on-list-click="bindListClick"></hot-news>
            <single-list :cat-id="curSubMenuItem.pkSerial" @on-list-click="bindListClick"></single-list>
          </template>

          <!-- 'list-card' 卡片列表 -->
          <template v-if="curSubMenuItem.type == 'list-card'">
            <hot-news :cat-id="curSubMenuItem.pkSerial" @on-list-click="bindListClick"></hot-news>
            <double-list :cat-id="curSubMenuItem.pkSerial" @on-list-click="bindListClick"></double-list>
          </template>

          <!-- feed-back 用户反馈 -->
          <template v-if="curSubMenuItem.type == 'feed-back'">
            <hot-news :cat-id="curSubMenuItem.pkSerial" @on-list-click="bindListClick"></hot-news>
            <feed-back :cat-id="curSubMenuItem.pkSerial" @on-list-click="bindListClick"></feed-back>
          </template>

          <!-- 'list-timeline' 焦点图列表 -->
          <template v-if="curSubMenuItem.type == 'list-timeline'">

            <time-list :cat-id="curSubMenuItem.pkSerial" @on-list-click="bindListClick"></time-list>
          </template>
        </template>


      </div>
    </div>

    <nav-footer></nav-footer>

  </div>
</template>

<script>
/**
 *
 * @description
 * @author Henson
 * @date 2021/03/06 16:22:03
 * @property
 * @event
 */
import navbar from '@/components/Menu.vue'
import TopImg from "@/components/TopImg.vue"
import subTabs from "@/components/Tabs.vue"
import SubTitle from "@/components/SubTitle.vue"
import navFooter from "@/components/Footer.vue"

import pageArticle from "@/components/Article.vue"
import ContentBlock from "@/components/ContentBlock.vue"
import SingleList from "@/components/SingleList.vue"
import DoubleList from "@/components/DoubleList.vue"
import TimeList from "@/components/TimeList.vue"

import BreadCrumb from '@/components/BreadCrumb.vue'

import { mapState } from 'vuex'

import HotNews from '@/components/HotNews.vue'
import FeedBack from '@/components/FeedBack.vue'

export default {
  components: {
    navbar,
    TopImg,
    subTabs,
    SubTitle,
    navFooter,
    pageArticle,
    ContentBlock,
    SingleList,
    DoubleList,
    TimeList,
    BreadCrumb,
    HotNews,
    FeedBack
  },
  data() {
    return {
      cdnUrl: this.$config.CDN_URL,
      mid: this.$route.query.mid? this.$route.query.mid : '',
      cid: this.$route.query.cid? this.$route.query.cid : '',
      aid: this.$route.query.aid? this.$route.query.aid : '',

      // image: '',
      articleTypeContent: '',

      articleObj: {},


    }
  },
  computed: {
    ...mapState({
      menuArray: state => state.menu.menuArray,
      menuTree: state => state.menu.menuTree,
    }),
    // 当前菜单
    curMenu: function() {
      return this.menuTree.find(item => item.pkSerial == this.mid)
    },

    // 当前菜单的所有子菜单
    curSubMenu: function() {
      if(!this.curMenu?.children.length) return []
      if(!this.cid) this.cid = this.curMenu.children[0].pkSerial
      return this.curMenu.children
    },
    // 当前子菜单项
    curSubMenuItem: function() {
      if(!this.curSubMenu.length) return {}
      let curMI = this.curSubMenu.find(item => item.pkSerial == this.cid)
      if(!curMI) curMI = this.curSubMenu[0]
      // this.curSubMenuItemType = curMI.type
      return curMI
    },

    banner: function() {
      if(!this.curMenu) return ''
      return this.cdnUrl + this.curMenu.image

    },

    // watchObj: function() {
    //   return {
    //     menuTree: this.menuTree,
    //     curSubMenu: this.curSubMenu,
    //     curSubMenuItem: this.curSubMenuItem,
    //     mid: this.mid,
    //     cid: this.cid,
    //   }
    // },

  },
  watch: {
    // watchObj: {
    //   handler: function(val) {
    //     if(!val.cid) return
    //     console.log(val.cid, '............')
    //     this.getData()
    //   },
    //   immediate: true,
    // },
    // 'curSubMenuItem.type': {
    //   handler: function(val) {
    //     if(val == 'article') {
    //       this.getData()
    //     }
    //   },
    //   immediate: true,
    // },
    aid: {
      handler: function(val) {
        if(!val) return

        this.$http.get(`/article/article/${val}`).then(res => {
          this.articleObj = res.data
        })
      },
      immediate: true,
    },
  },
  created() {

  },
  mounted() {

  },
  methods: {
    // 切换tab
    bindChangeTap: function(key) {
      this.cid = key
      this.aid = ''
      this.getData()
      // this.$router.push({path: '/navigation', query: {mid: this.curMenu.pkSerial, cid: key}})
    },
    //
    getData: function() {
      // console.log('sss',this.curSubMenuItem.type)

      // 如果是“article”类型的
      // if(this.curSubMenuItem.type == 'article') {
      //   this.$http.get('/article/article/list', { params: {categoryId: this.cid} }).then(res => {
      //     this.articleTypeContent = res.rows.length? res.rows[0].content : ''
      //   })
      // }
      // 菜单栏目  menu
      // 文章内容    article
      // 普通列表  list
      // 焦点图列表  list-img
      // 卡片式列表  list-card
      // 时间轴列表  list-timeline
      // else if(['list', 'list-img'].includes(this.curSubMenuItem.type)) {

      // }
      // else {

      // }
    },

    // 跳文章详情
    bindListClick: function(articleid) {
      this.$router.push({path: '/navigation', query: {mid: this.curMenu.pkSerial, cid: this.cid, aid: articleid, r: Math.random()}})
    },
  },
}
</script>
<style lang='scss' scoped>
.navbar {
  position: fixed;
  z-index: 9;
  width: 100%;
  // min-width: 1200px;
  // overflow-x: auto;
  padding-bottom: 50px;
  background: transparent;
}
.mobile .navbar {
  z-index: 9;
  width: 100%;
  padding-bottom: 0;
}

.main-content {
  min-height: calc(50vh - 226px);
}
</style>
